import * as React from 'react';
import styled from 'styled-components';
import { useRouteMatch, NavLink } from 'react-router-dom';

import { IoPerson } from 'react-icons/io5';
import { OysterLogo } from '@oysterjs/ui/Logo';

const LeftMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const NavItem = styled.li`
  padding: 16px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  :hover {
    color: #0ea5e9;
  }

  :active {
    color: #269985;
  }
`;

const RightMenuContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const DesktopNavBar: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <LeftMenuContainer>
        <NavItem>
          <NavLink exact to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <OysterLogo />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/policies`} activeClassName="active-tab">
            Policies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/claims`} activeClassName="active-tab">
            Claims
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink exact to={`${url.replace(/\/+$/, '')}/invite`} activeClassName="active-tab">
            Invite a Friend
          </NavLink>
        </NavItem>
      </LeftMenuContainer>
      <RightMenuContainer>
        <NavItem>
          <NavLink to={`${url.replace(/\/+$/, '')}/account`} activeClassName="active-tab">
            <IoPerson aria-label="Account" style={{ verticalAlign: 'middle' }} />
          </NavLink>
        </NavItem>
      </RightMenuContainer>
    </>
  );
};
