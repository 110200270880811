import * as React from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';

import { IoArrowForward, IoLockClosed, IoStar, IoTrashOutline } from 'react-icons/io5';
import { Button, ButtonContainer, ButtonLink, UnstyledButton } from '@oysterjs/ui/Button';
import { getUser, resetToken } from '@oysterjs/core/auth';
import getStripe from '@oysterjs/core/stripe';
import { PageSection } from '@oysterjs/ui/Page';
import { Table } from '@oysterjs/ui/Table';
import { Badge } from '@oysterjs/ui/Badge';
import {
  AccountSummary,
  PaymentMethod as IPaymentMethod,
  Statement,
  UserVerification,
  VerificationStatus
} from '@oysterjs/types';
import {
  createPaymentMethod,
  retrieveVerificationSession,
  deletePaymentMethod,
  getAccountStatement,
  getAccountSummary,
  makePaymentMethodDefault
} from '@oysterjs/core/api/user';
import { Stripe } from '@stripe/stripe-js';
import { Redirect, Route as BrowserRoute, Switch, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Loadable } from '@oysterjs/ui/Loadable';
import { Banner } from '@oysterjs/ui/Banner';
import { Modal } from '@oysterjs/ui/Modal';
import { PaymentMethod, PaymentMethodInput } from '@oysterjs/core/payment';
import { useWindowDimensions } from '@oysterjs/core/window';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { userSignOut } from '@oysterjs/core/api/auth';

const Route = Sentry.withSentryRouting(BrowserRoute);

const PolicyStatementContainer = styled.div`
  padding: 10px 0px;
  margin: 20px 0px 0px 0px;
  border-top: 2px solid #f2f2f2;

  h2 {
    margin: 0 0 5px 0;
    color: #000000;
    font-weight: 500;
    font-size: 1.2em;
  }
`;

export const PolicyStatement: React.FunctionComponent<
  React.PropsWithChildren<{ title: string; total: number }>
> = (props) => (
  <PolicyStatementContainer>
    {props.children}
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '2px solid #f2f2f2',
        padding: '10px 0px',
        margin: '10px 0px'
      }}
    >
      <h2 style={{ paddingRight: '20px' }}>{props.title}</h2>
      <h2>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'usd',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(props.total)}
      </h2>
    </div>
  </PolicyStatementContainer>
);

const PolicyLineItemContainer = styled.div`
  padding: 5px 0px 5px 0px;
  box-sizing: border-box;
`;

export const PolicyLineItem: React.FunctionComponent<{
  title: string;
  description: string;
  total: number;
}> = (props) => (
  <PolicyLineItemContainer>
    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9em' }}>
      <span>{props.title}</span>
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'usd',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(props.total)}
      </span>
    </div>
    <div style={{ fontSize: '0.8em', color: '#999999', maxWidth: 'calc(100% - 80px)' }}>
      {props.description}
    </div>
  </PolicyLineItemContainer>
);

export const PolicyTotalContainer = styled.div`
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  padding: 20px 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PolicyTotal: React.FunctionComponent<{
  currency: string;
  subtotal: number;
  taxrate: number;
  taxtotal: number;
  total: number;
}> = (props) => (
  <PolicyTotalContainer>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>Subtotal</span>
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'usd',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(props.subtotal)}
      </span>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        Tax (
        {new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(props.taxrate)}
        )
      </span>
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(props.taxtotal)}
      </span>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 500,
        fontSize: '1.2em'
      }}
    >
      <span>Total</span>
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(props.total)}
      </span>
    </div>
  </PolicyTotalContainer>
);

const PropertyList = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 0.8em;
  color: #999999;
`;

const PropertyListItem = (props: { name: string; value: string }) => (
  <li style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px 0px 0px' }}>
    <span style={{ paddingRight: '20px' }}>{props.name}</span>
    <span>{props.value}</span>
  </li>
);

const StatementPage = (props: { statement: Statement }): JSX.Element => {
  const date = new Intl.DateTimeFormat([], { month: 'long', year: 'numeric' }).format(
    new Date(props.statement.StatementDate)
  );
  return (
    <PageSection>
      <h1>Your {date} Statement</h1>
      {props.statement.PolicyStatements.map((statement) => (
        <PolicyStatement key={statement.Name} title={statement.Name} total={statement.Total}>
          {statement.LineItems.map((item) => (
            <PolicyLineItem
              key={item.Title}
              title={item.Title}
              description={item.Description}
              total={item.Price.Amount}
            />
          ))}
          {statement.Adjustments.map((item) => (
            <PolicyLineItem
              key={item.Title}
              title={item.Title}
              description={item.Description}
              total={item.Price.Amount}
            />
          ))}
        </PolicyStatement>
      ))}
      <PolicyTotal
        subtotal={props.statement.SubTotal}
        taxtotal={props.statement.TaxTotal}
        taxrate={props.statement.TaxRate}
        total={props.statement.Total}
        currency={props.statement.Currency}
      />
      <PropertyList>
        <PropertyListItem name="Statement ID" value={props.statement.ID} />
        <PropertyListItem
          name="Generated Date"
          value={new Date(props.statement.GeneratedAt).toUTCString()}
        />
        <PropertyListItem
          name="Statement Period End Date"
          value={new Date(props.statement.StatementDate).toUTCString()}
        />
        <PropertyListItem
          name="Payment Due Date"
          value={new Date(props.statement.StatementDate).toUTCString()}
        />
      </PropertyList>
    </PageSection>
  );
};

const BillingPage = (props: { account: AccountSummary }): JSX.Element => {
  const [account, setAccount] = React.useState(props.account);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();

  const [showAddPaymentMethod, setShowAddPaymentMethod] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<IPaymentMethod>();
  const [showMakeDefaultConfirmation, setShowMakeDefaultConfirmation] =
    React.useState<IPaymentMethod>();

  React.useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  return (
    <>
      {showDeleteConfirmation && (
        <Modal
          style={{ maxWidth: '500px' }}
          onClose={() => setShowDeleteConfirmation(undefined)}
          headerContents={<h3 style={{ margin: 0 }}>Confirm delete</h3>}
        >
          <p style={{ textAlign: 'center' }}>
            Are you sure you want to delete the card ending in{' '}
            <b>{showDeleteConfirmation.LastFour}</b>? This cannot be undone.
          </p>
          <ButtonContainer center>
            <Button onClick={() => setShowDeleteConfirmation(undefined)}>Cancel</Button>
            <Button
              primary
              loading={loading}
              icon={<IoArrowForward />}
              onClick={() => {
                setLoading(true);
                deletePaymentMethod(showDeleteConfirmation.ID)
                  .then(() => getAccountSummary())
                  .then(setAccount)
                  .catch((err) => setError(err.message))
                  .finally(() => {
                    setShowDeleteConfirmation(undefined);
                    setLoading(false);
                  });
              }}
            >
              Confirm
            </Button>
          </ButtonContainer>
          {error && <ErrorDisplay style={{ textAlign: 'center' }}>{error}</ErrorDisplay>}
        </Modal>
      )}
      {showMakeDefaultConfirmation && (
        <Modal
          style={{ maxWidth: '500px' }}
          onClose={() => setShowMakeDefaultConfirmation(undefined)}
          headerContents={<h3 style={{ margin: 0 }}>Change default card</h3>}
        >
          <p style={{ textAlign: 'center' }}>
            Are you sure you want make the card ending in{' '}
            <b>{showMakeDefaultConfirmation.LastFour}</b> the default payment card? This can be
            changed any time, as long as there is more than one card.
          </p>
          <ButtonContainer center>
            <Button onClick={() => setShowMakeDefaultConfirmation(undefined)}>Cancel</Button>
            <Button
              primary
              loading={loading}
              icon={<IoArrowForward />}
              onClick={() => {
                setLoading(true);
                makePaymentMethodDefault(showMakeDefaultConfirmation.ID)
                  .then(() => getAccountSummary())
                  .then(setAccount)
                  .catch((err) => setError(err.message))
                  .finally(() => {
                    setShowMakeDefaultConfirmation(undefined);
                    setLoading(false);
                  });
              }}
            >
              Confirm
            </Button>
          </ButtonContainer>
          {error && <ErrorDisplay style={{ textAlign: 'center' }}>{error}</ErrorDisplay>}
        </Modal>
      )}
      {showAddPaymentMethod && (
        <Modal
          style={{ maxWidth: '500px' }}
          onClose={() => setShowAddPaymentMethod(false)}
          headerContents={<h3 style={{ margin: 0 }}>Add a card</h3>}
        >
          <PaymentMethodInput
            continueLabel="Add Card"
            returnUrl={window.location.href}
            setupOnly={true}
            getPaymentIntent={() => createPaymentMethod()}
          />
        </Modal>
      )}
      <h2>Payment Cards</h2>
      <Table>
        <thead>
          <tr>
            <td>Card</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {(account.PaymentMethods || []).map((paymentMethod) => (
            <tr key={paymentMethod.ID}>
              <td>
                <PaymentMethod paymentMethod={paymentMethod} />
              </td>
              <td style={{ textAlign: 'right' }}>
                {!paymentMethod.Default && (
                  <UnstyledButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMakeDefaultConfirmation(paymentMethod);
                    }}
                    aria-label="Edit Payment Method"
                  >
                    <IoStar
                      style={{
                        fontSize: '1.3em',
                        color: '#666666',
                        cursor: 'pointer',
                        padding: '0px 4px'
                      }}
                    />
                  </UnstyledButton>
                )}
                {!paymentMethod.Default && (
                  <UnstyledButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteConfirmation(paymentMethod);
                    }}
                    aria-label="Delete Payment Method"
                  >
                    <IoTrashOutline
                      style={{
                        fontSize: '1.3em',
                        color: '#666666',
                        cursor: 'pointer',
                        padding: '0px 4px'
                      }}
                    />
                  </UnstyledButton>
                )}
                {paymentMethod.Default && <Badge color="#7ACF59" label="Default" />}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ButtonContainer>
        <Button primary onClick={() => setShowAddPaymentMethod(true)}>
          Add Payment Card
        </Button>
      </ButtonContainer>
      <p>
        NOTE: Adding a card or switching default card will only apply to future policies. Please
        contact us at{' '}
        <a target="_blank" href={`mailto:support@withoyster.com`}>
          support@withoyster.com
        </a>{' '}
        if you need to change payment method of your existing policies.
      </p>
    </>
  );
};

const AsyncStatementPage = ({ statementId }: { statementId: string }) => (
  <Loadable request={getAccountStatement(statementId)}>
    {({ Statement }) => <StatementPage statement={Statement} />}
  </Loadable>
);

const AccountHeaderContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  gap: 40px;
`;

const ProfileImage = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid #eaeaea;
  background: #fafafa;
`;

const AccountDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const AccountNavigationContainer = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 40px;

  a,
  a:visited {
    color: #333333;
    text-decoration: none;
  }

  a.active-tab {
    color: #0ea5e9;
  }
`;

const AccountNavigationItemContainer = styled.li`
  transition: 0.15s all ease-in-out;
  /* padding: 0px; */
  font-weight: 500;
  cursor: pointer;

  :hover {
    color: #0ea5e9;
  }

  :active {
    color: #269985;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  padding-top: 0.67em;

  h1 {
    width: 100%;
  }
`;

const ProfileActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 120px;
`;

const AccountHeader = (props: {
  email: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
}) => {
  const { url } = useRouteMatch();
  const { innerWidth } = useWindowDimensions();
  const [loading, setLoading] = React.useState(false);

  const signOut = () => {
    setLoading(true);
    userSignOut().finally(() => {
      setLoading(false);
      resetToken();
    });
  };

  return (
    <AccountHeaderContainer>
      {innerWidth > 600 && (
        <ProfileImage
          width={150}
          height={150}
          src={`https://www.gravatar.com/avatar/${props.email.replace(
            /[^a-zA-Z0-9]/,
            ''
          )}?d=identicon&size=300`}
        />
      )}
      <AccountDetailsContainer>
        <div style={{ width: '100%' }}>
          <HeaderContainer>
            <h1 style={{ margin: '0', fontWeight: 500 }}>
              {props.firstName} {props.lastName}
            </h1>
            <ProfileActionsContainer>
              <Button primary loading={loading} onClick={signOut}>
                Sign Out
              </Button>
            </ProfileActionsContainer>
          </HeaderContainer>
          <p style={{ color: '#999999', marginTop: '5px' }}>{props.email}</p>
        </div>
        <nav>
          <AccountNavigationContainer>
            <AccountNavigationItemContainer>
              <NavLink exact to={`${url.replace(/\/+$/, '')}/profile`} activeClassName="active-tab">
                Profile
              </NavLink>
            </AccountNavigationItemContainer>
            <AccountNavigationItemContainer>
              <NavLink exact to={`${url.replace(/\/+$/, '')}/billing`} activeClassName="active-tab">
                Billing
              </NavLink>
            </AccountNavigationItemContainer>
          </AccountNavigationContainer>
        </nav>
      </AccountDetailsContainer>
    </AccountHeaderContainer>
  );
};

const IdentityVerification = (props: { verification?: UserVerification }) => {
  const [stripe, setStripe] = React.useState<Stripe | null>(null);
  const [verification, setVerification] = React.useState(props.verification);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    setLoading(true);
    getStripe()
      .then((stripe) => setStripe(stripe))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  const render = (badge: JSX.Element, text: JSX.Element, action?: JSX.Element) => (
    <>
      <h2>
        Your Identity
        <span
          style={{
            fontSize: '14px',
            verticalAlign: 'middle',
            fontWeight: 400,
            paddingLeft: '10px'
          }}
        >
          {badge}
        </span>
      </h2>
      {text}
      {action}
      {error && <p style={{ color: '#d1344b' }}>{error}</p>}
    </>
  );

  const handleVerify = (secret: string) => {
    setLoading(true);
    return stripe
      ?.verifyIdentity(secret)
      .then((res) => {
        if (res.error) {
          setError(res.error.message || '');
        }
      })
      .then(() => getAccountSummary())
      .then((res) => setVerification(res.Verification))
      .finally(() => setLoading(false));
  };

  const onVerify = () =>
    retrieveVerificationSession().then((res) => {
      setVerification(res.VerificationSession);
      handleVerify(res.VerificationSession.Secret);
    });

  // not started - show verify button
  if (!verification || !verification?.Status) {
    return render(
      <Badge color="#fd9e57" label="Verification required" />,
      <p>
        To complete the account setup, we need to verify your identity. Verification usually takes
        less than a few minutes and is encrypted. Oyster works with Stripe to conduct identity
        verification online. Stripe will ask for your consent before collecting your information,
        and they will only use your verification data in accordance with their privacy policy and
        the permissions you grant for the verification process.
      </p>,
      <Button
        loading={loading}
        leftIcon={<IoLockClosed />}
        icon={<IoArrowForward />}
        primary
        onClick={onVerify}
      >
        Verify Identity
      </Button>
    );
  }

  // started but not completed - show verify button
  const started = verification?.Status === VerificationStatus.RequiresInput && !verification?.Error;

  // completed but verification pending - show spinner with message
  const pending = verification?.Status === VerificationStatus.Processing;

  // completed and denied - option to try again, refer to customer support
  const denied = verification?.Status === VerificationStatus.RequiresInput && verification?.Error;

  // completed and verified - show success message and redirect
  const verified = verification?.Status === VerificationStatus.Verified;

  const canceled = verification?.Status === VerificationStatus.Canceled;

  if (verified) {
    return render(
      <Badge color="#00c7a5" label="Verified" />,
      <p>Your identity has been successfully verified.</p>
    );
  }

  if (pending) {
    return render(
      <Badge color="#fd9e57" label="Verification in progress" />,
      <p>
        Thank you for submitting your information. Identity verification can take up to 48 hours.
        Please reach out to <a href="mailto:support@withoyster.com">support@withoyster.com</a> if
        you need help.
      </p>
    );
  }

  if (denied) {
    return render(
      <Badge color="#CF5959" textColor="#ffffff" label="Verification failed" />,
      <p>
        Identity verification failed. Reason: {verification?.Error} Please try again or reach out to{' '}
        <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we can help resolve
        the issue.
      </p>,
      <Button
        loading={loading}
        leftIcon={<IoLockClosed />}
        icon={<IoArrowForward />}
        primary
        onClick={onVerify}
      >
        Try Verification Again
      </Button>
    );
  }

  if (started) {
    return render(
      <Badge color="#fd9e57" label="Verification incomplete" />,
      <p>
        We still need a bit more information before we can verify your identity. Please continue by
        clicking the button below. If you need assistance, feel free to reach out to{' '}
        <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we can help resolve
        the issue.
      </p>,
      <Button
        loading={loading}
        leftIcon={<IoLockClosed />}
        icon={<IoArrowForward />}
        primary
        onClick={onVerify}
      >
        Continue Verification
      </Button>
    );
  }

  // Canceled is a weird state because only us can request cancellation of a session.
  // Users should not be able to retry until they contact us.
  if (canceled) {
    return render(
      <Badge color="#CF5959" textColor="#ffffff" label="Verification failed" />,
      <p>
        Identity verification failed. Reason: Verification session has been canceled. Please reach
        out to <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we can help
        resolve the issue.
      </p>
    );
  }

  return null;
};

export const VerifyIdentityBanner = (props: { accountSummary?: AccountSummary }): JSX.Element => (
  <Loadable
    request={props.accountSummary ? Promise.resolve(props.accountSummary) : getAccountSummary()}
    hideSpinner
  >
    {(res) => {
      if (res.Verification?.Status === VerificationStatus.Verified) {
        return null;
      }

      let title = '',
        description = '',
        hasAction = true;

      if (
        !res.Verification ||
        (res.Verification?.Status === VerificationStatus.RequiresInput && !res.Verification?.Error)
      ) {
        title = 'Please verify your identity';
        description = 'Oyster needs to verify your identity before any claims can be processed.';
      }

      if (res.Verification?.Status === VerificationStatus.Processing) {
        title = 'Your identity is being verified';
        description = 'Oyster is currently verifying your identity. This can take up to 48 hours.';
        hasAction = false;
      }

      if (res.Verification?.Status === VerificationStatus.Canceled) {
        title = 'Oyster could not verify your identity';
        description =
          'Identity verification was canceled. Check your account profile for next steps.';
      }

      if (
        res.Verification?.Status === VerificationStatus.RequiresInput &&
        res.Verification?.Error
      ) {
        title = 'Oyster could not verify your identity';
        description =
          'There was an error verifying your identity: ' +
          res.Verification?.Error +
          ' Check your account profile for next steps.';
      }

      return (
        <Banner
          title={title}
          description={description}
          bannerAction={
            hasAction ? (
              <ButtonLink href="/account/profile" primary icon={<IoArrowForward />}>
                Continue to Profile
              </ButtonLink>
            ) : undefined
          }
        ></Banner>
      );
    }}
  </Loadable>
);

export const AccountPage = (props: { account: AccountSummary }): JSX.Element => {
  const { path } = useRouteMatch();
  return (
    <>
      <PageSection>
        <AccountHeader
          email={getUser()?.Email || ''}
          firstName={getUser()?.FirstName || ''}
          lastName={getUser()?.LastName || ''}
          createdAt={new Date(getUser()?.CreatedAt || new Date())}
        />
      </PageSection>
      <Switch>
        <Route exact path={`${path.replace(/\/+$/, '')}/profile`}>
          <PageSection>
            <h1>Profile</h1>
            <IdentityVerification verification={props.account.Verification} />
          </PageSection>
        </Route>
        <Route exact path={`${path.replace(/\/+$/, '')}/billing`}>
          <PageSection>
            <h1>Billing</h1>
            <BillingPage account={props.account} />
          </PageSection>
        </Route>
        <Route exact path={`${path.replace(/\/+$/, '')}/settings`}>
          <PageSection>
            <h1>Settings</h1>
            <p>Nothing here yet.</p>
          </PageSection>
        </Route>
        <Route
          path={`${path.replace(/\/+$/, '')}/statement/:id`}
          render={(props) => <AsyncStatementPage statementId={props.match?.params.id || ''} />}
        />
        <Redirect to={`${path.replace(/\/+$/, '')}/profile`}></Redirect>
      </Switch>
    </>
  );
};
