import * as Sentry from '@sentry/react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory
} from 'react-router-dom';

const Route = Sentry.withSentryRouting(BrowserRoute);

import { getAccountSummary, getPolicies, getPolicy } from '@oysterjs/core/api/user';
import { getClaim, getClaims } from '@oysterjs/core/api/claims';
import { Loadable } from '@oysterjs/ui/Loadable';
import { PageContainer } from '@oysterjs/ui/Page';
import { ClaimState } from '@oysterjs/types';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';

import { NavBar } from './navbar';

import { AccountPage } from './account';
import { ClaimPage, ClaimsPage, ResumeClaimsPage } from './claims';
import { EditPolicyPage } from './edit';
import { PoliciesPage } from './policies';
import { PolicyPage } from './policy';
import { InvitePage } from './invite';

const AsyncPoliciesPage = () => (
  <Loadable request={Promise.all([getPolicies(), getAccountSummary()])}>
    {([policies, account]) => <PoliciesPage policies={policies.Policies} account={account} />}
  </Loadable>
);

const AsyncClaimsPage = () => (
  <Loadable request={Promise.all([getClaims(), getPolicies()])}>
    {([d, p]) => <ClaimsPage claims={d.Claims} policies={p.Policies} />}
  </Loadable>
);

const AsyncPolicyPage = ({ policyId }: { policyId: string }) => (
  <Loadable request={Promise.all([getPolicy(policyId), getAccountSummary()])}>
    {([policy, account]) => <PolicyPage policy={policy.Policy} account={account} />}
  </Loadable>
);

const AsyncEditPolicyPage = ({ policyId }: { policyId: string }) => (
  <Loadable request={getPolicy(policyId)}>{() => <EditPolicyPage />}</Loadable>
);

const AsyncClaimPage = ({ claimId }: { claimId?: string }) => (
  <Loadable
    request={Promise.all([claimId ? getClaim(claimId) : Promise.resolve(undefined), getPolicies()])}
  >
    {([d, p]) => <ClaimPage claim={d?.Claim} policies={p.Policies} />}
  </Loadable>
);

const AsyncAccountPage = () => (
  <Loadable request={getAccountSummary()}>
    {(account) => <AccountPage account={account} />}
  </Loadable>
);

const AsyncResumeClaimPage = () => {
  const history = useHistory();
  return (
    <Loadable request={getClaims()}>
      {(d) => {
        const notFinished = d.Claims.filter(
          (c) => c.State != ClaimState.denied && c.State != ClaimState.settled
        );
        if (notFinished.length === 0) {
          history.push('/claims/start');
          return null;
        }

        return <ResumeClaimsPage claims={notFinished} />;
      }}
    </Loadable>
  );
};

export default (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <PageContainer>
      <NavBar />
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path={`${path.replace(/\/+$/, '')}/policies/:id/edit`}
            render={(props) => <AsyncEditPolicyPage policyId={props.match?.params.id || ''} />}
          ></Route>
          <Route
            path={`${path.replace(/\/+$/, '')}/policies/:id`}
            render={(props) => <AsyncPolicyPage policyId={props.match?.params.id || ''} />}
          ></Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/policies`}>
            <AsyncPoliciesPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/claims`}>
            <AsyncClaimsPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/claims/start`}>
            <AsyncClaimPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/claims/resume`}>
            <AsyncResumeClaimPage />
          </Route>
          <Route
            path={`${path.replace(/\/+$/, '')}/claims/:id`}
            render={(props) => <AsyncClaimPage claimId={props.match?.params.id || ''} />}
          ></Route>
          <Route path={`${path.replace(/\/+$/, '')}/app/jewelry`}>
            <AsyncPoliciesPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/app/bike`}>
            <AsyncPoliciesPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/app/phone`}>
            <AsyncPoliciesPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/app/electronics`}>
            <AsyncPoliciesPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/app/offroad_vehicle`}>
            <AsyncPoliciesPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/app/motorcycle`}>
            <AsyncPoliciesPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/policy/:id/complete`}>
            <AsyncPoliciesPage />
          </Route>
          <Route exact path={`${path.replace(/\/+$/, '')}/invite`}>
            <InvitePage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/account`}>
            <AsyncAccountPage />
          </Route>
          <Redirect to={`${path.replace(/\/+$/, '')}/policies`} />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
