import { Claim, ValidationError } from '@oysterjs/types';
import { Delete, Get, Post, Put } from './base';

export const getClaims = () => Get<{ Claims: Claim[] }>('/user/claims');

export const getClaim = (claimId: string) => Get<{ Claim: Claim }>(`/user/claims/${claimId}`);

export const createClaim = (policyId: string, productId: string) =>
  Post<{ Claim: Claim }>('/user/claims', { PolicyID: policyId, ProductID: productId });

export const resetClaim = (claimId: string, policyId: string, productId: string) =>
  Post<{ Claim: Claim }>(`/user/claims/${claimId}/reset`, {
    PolicyID: policyId,
    ProductID: productId
  });

export const updateClaim = (claim: Claim): Promise<{ Claim: Claim; NextError?: ValidationError }> =>
  Put<{ Claim: Claim; NextError?: ValidationError }>(`/user/claims/${claim.ID}`, { Claim: claim });

export const deleteClaim = (claimId: string) => Delete(`/user/claims/${claimId}`);

export const submitClaim = (claimId: string) =>
  Post<{ Claim: Claim }>(`/user/claims/${claimId}/submit`);

export const createClaimAttachments = (claimId: string, group: string, files: File[]) => {
  const form = new FormData();
  form.set('group', group);
  files.forEach((file) => form.append('files[]', file));
  return Post<{ Claim: Claim }>(`/user/claims/${claimId}/attachments`, undefined, { body: form });
};

export const deleteClaimAttachment = (claimId: string, attachmentId: string) =>
  Delete(`/user/claims/${claimId}/attachments/${attachmentId}`);
