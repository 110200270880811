import { RefObject } from 'react';
import React from 'react';

export const useDynamicRefs = <T,>(): {
  getRef: (key: string) => RefObject<T> | undefined;
  setRef: (key: string) => RefObject<T>;
} => {
  const [refs, setRefs] = React.useState({});

  const getRef = <T,>(key: string) => refs[key] as RefObject<T> | undefined;
  const setRef = <T,>(key: string) => {
    const existingRef = getRef<T>(key);
    if (existingRef) {
      return existingRef;
    }

    const ref = React.createRef<T>();
    setRefs((prev) => ({ ...prev, [key]: ref }));
    return ref;
  };

  return { getRef, setRef };
};
