import * as React from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import { Button, ButtonContainer, ButtonLink } from '@oysterjs/ui/Button';
import {
  getPolicyToActivate,
  updatePolicyActivation,
  confirmActivationPolicy,
  validateUserActivationPolicy
} from '@oysterjs/core/api/activateDashboard';
import { Spinner } from '@oysterjs/ui/Spinner';
import { getUser } from '@oysterjs/core/auth';
import {
  IoArrowForward,
  IoBagCheckOutline,
  IoBicycleOutline,
  IoCheckmarkDoneOutline,
  IoClipboardOutline,
  IoDocumentTextOutline,
  IoHomeOutline,
  IoPersonOutline,
  IoPhonePortraitOutline
} from 'react-icons/io5';
import { PageSection } from '@oysterjs/ui/Page';
import {
  Insured,
  Policy,
  PolicyType,
  Product,
  ReferralChannelType,
  ValidationError
} from '@oysterjs/types';
import { Page, PageProps, ExistingUserWrapper } from '@oysterjs/getoyster/pages/page';
import { Loadable } from '@oysterjs/ui/Loadable';
import {
  CriteriaPage as MinicoJewelryCriteriaPage,
  LocationPage as MinicoLocationPage
} from '@oysterjs/getoyster/pages/jewelry/minico';
import {
  CriteriaPage as ChubbJewelryCriteriaPage,
  LocationPage as ChubbLocationPage
} from '@oysterjs/getoyster/pages/jewelry/chubb';
import { CriteriaPage as BikeCriteriaPage } from '@oysterjs/getoyster/pages/bike';
import { CriteriaPage as ElectronicsCriteriaPage } from '@oysterjs/getoyster/pages/electronics';
import { CriteriaPage as ATVCriteriaPage } from '@oysterjs/getoyster/pages/motor';
import { getPaymentPage } from '@oysterjs/getoyster/pages/payment';
import { getCoveragePage } from '@oysterjs/getoyster/pages/coverage';
import { useAnalytics } from '@oysterjs/core/analytics/analytics';
import { GetOysterEventNames } from '@oysterjs/core/analytics/googletags';
import { ErrorType, WrappedError, ErrorCode } from '@oysterjs/core/errors';
import { ProductInfoPage } from '@oysterjs/getoyster/pages/product';
import { BikeFormData, CollectBikeInfo } from '@oysterjs/getoyster/pages/bike/collect';
import { userGetPaymentSession } from '@oysterjs/core/api/user';
import {
  CollectElectronicsInfo,
  ElectronicsFormData
} from '@oysterjs/getoyster/pages/electronics/collect';

const ActivationPage = (props: {
  token: string;
  policy: Policy;
  insured: Insured;
  onActivate: (policy: Policy) => void;
  overrideManualActivationTrigger?: boolean;
}): JSX.Element => {
  const [policy, setPolicy] = React.useState(props.policy);
  const [loading, setLoading] = React.useState(true);

  const [error, setError] = React.useState('');
  const [submissionError, setSubmissionError] = React.useState({
    Message: '',
    RequestID: ''
  });
  const analytics = useAnalytics();

  React.useEffect(() => {
    confirmActivationPolicy(props.token, props.overrideManualActivationTrigger)
      .then(({ Policy }) => {
        analytics.event(GetOysterEventNames.ApplicationSubmitted, {
          policy_id: Policy.ID,
          policy_type: Policy.Type,
          value: Policy.Price?.Amount?.toString() || '0.0',
          currency: Policy.Price?.Currency || 'USD',
          transaction_id: Policy.ID,
          referral_channel:
            Policy.ReferralChannelInfo?.ReferralChannel ||
            ReferralChannelType.ReferralChannelDirect,
          ...Policy.ReferralChannelInfo?.Settings
        });
        setPolicy(Policy);
      })
      .catch((e) => {
        const err = WrappedError.asWrappedError(e);
        if (err.type() === ErrorType.processingError && err.code() === ErrorCode.submissionFailed) {
          setSubmissionError({
            Message: err.message,
            RequestID: err.metadata().RequestID
          });
        } else {
          setError(err.message);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    setPolicy(props.policy);
  }, [props.policy]);

  return (
    <>
      {loading && (
        <>
          <PageSection>
            <h1>Welcome, {props.insured.FirstName}!</h1>
            <p>
              Thanks for choosing the {props.policy.Name}. We're glad you're joining us! Just one
              moment as we complete your registration...
            </p>
            <PageSection noBorder noPadding centered>
              <Spinner color="#333333" />
            </PageSection>
          </PageSection>
        </>
      )}
      {!loading && !error && !submissionError.Message && (
        <>
          <PageSection noBorder>
            <h1>Welcome, {props.insured.FirstName}!</h1>
            <p>
              Congratulations! Your registration is complete and your information has been
              confirmed. We'll send you a final confirmation when your coverage becomes active.
            </p>
          </PageSection>
          <PageSection noPadding style={{ paddingBottom: '40px' }}>
            <ButtonContainer>
              <ButtonLink
                icon={<IoArrowForward />}
                primary
                onClick={() => props.onActivate(policy)}
                href={`/policies/${props.policy.ID}/overview`}
              >
                Continue to Dashboard
              </ButtonLink>
            </ButtonContainer>
          </PageSection>
        </>
      )}
      {!loading && error && (
        <>
          <PageSection noBorder>
            <h1>Oops!</h1>
            <p>
              There was an error activating your coverage. Please reach out to{' '}
              <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we'll help
              resolve the issue.
            </p>
          </PageSection>
          <PageSection noPadding style={{ paddingBottom: '40px' }}>
            {error && <p style={{ color: '#d1344b' }}>{error}</p>}
          </PageSection>
        </>
      )}
      {!loading && submissionError.Message && (
        <>
          <PageSection noBorder>
            <h1>Oops!</h1>
            <p>
              There was an error activating your coverage. Please reach out to{' '}
              <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we'll help
              resolve the issue.
            </p>
          </PageSection>
          <PageSection noPadding style={{ paddingBottom: '40px' }}>
            {submissionError.Message && (
              <p style={{ color: '#d1344b' }}>{submissionError.Message}</p>
            )}
            {submissionError.RequestID && (
              <p>
                <pre style={{ display: 'inline' }}>
                  Transaction Reference ID: {submissionError.RequestID}
                </pre>
              </p>
            )}
          </PageSection>
        </>
      )}
    </>
  );
};

const PageWrapper = (props: {
  policyId: string;
  policy: Policy;
  page: React.FunctionComponent<React.PropsWithChildren<PageProps>>;
  pages: Array<Page>;
}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [policy, setPolicy] = React.useState(props.policy);
  const [validationError, setValidationError] = React.useState<ValidationError>();

  const currentPageIndex = props.pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  const onUpdate = async (policy: Policy, forceUpdate?: boolean) => {
    if (!forceUpdate) {
      setPolicy(policy);
      return policy;
    }

    try {
      const res = await updatePolicyActivation(props.policyId, policy);
      setPolicy(res.Policy);
      return res.Policy;
    } catch (err) {
      // ignore errors here
      setPolicy(policy);
      return policy;
    }
  };

  const onBack = () => {
    if (currentPageIndex > 0) {
      history.push(props.pages[currentPageIndex - 1].path.replace(':id', props.policyId));
    }
  };

  const onNext = async () => {
    setLoading(true);
    try {
      const res = await updatePolicyActivation(props.policyId, policy);
      setValidationError(undefined);
      if (res && res.Policy) {
        setPolicy(res.Policy);
      }
      if (res.NextValidationError) {
        throw WrappedError.fromValidationError(res.NextValidationError);
      }
      if (currentPageIndex < props.pages.length - 1) {
        history.push(props.pages[currentPageIndex + 1].path.replace(':id', props.policyId));
      }
      return true;
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      if (err.type() === ErrorType.validationError) {
        // Show the user the error in err.Message and highlight the
        // field corresponding to err.Field.
        setValidationError(err.getValidationError());

        // find page to go to if any
        let nextPageIndex = props.pages.findIndex((page) =>
          page.hasError(err.getValidationError())
        );
        if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
          nextPageIndex = currentPageIndex + 1;
        }
        if (nextPageIndex !== currentPageIndex && nextPageIndex < props.pages.length) {
          history.push(props.pages[nextPageIndex].path.replace(':id', props.policyId));
        }
      }
    } finally {
      setLoading(false);
    }

    return false;
  };

  return React.createElement(props.page, {
    loading,
    policy,
    validationError,
    onNext,
    onBack,
    onUpdate
  });
};

const CollectPagePage = <T,>(
  props: React.PropsWithChildren<{
    policy: Policy;
    pages: Array<Page>;
    component: (
      initialFormData: T,
      onSubmit: (
        product: Product
      ) => Promise<{ Policy: Policy; NextValidationError?: ValidationError }>,
      onContinue: () => void
    ) => JSX.Element;
  }>
) => {
  const history = useHistory();
  const [policy, setPolicy] = React.useState(props.policy);

  const currentPageIndex = props.pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  const mergeProduct = (a: Product, b: Product) => ({
    ID: b.ID,
    Type: b.Type,
    SKU: b.SKU,
    Name: a.Name || b.Name,
    Description: a.Description || b.Description,
    ImageURL: b.ImageURL,
    Quantity: b.Quantity,
    Price: a.Price || b.Price,
    Pending: b.Pending,
    Details: a.Details || b.Details,
    HasNotReceived: a.HasNotReceived || b.HasNotReceived
  });

  const onSubmit = async (product: Product) => {
    const res = await updatePolicyActivation(props.policy.ID, {
      ...policy,
      InsuredItems: [mergeProduct(product, policy.InsuredItems[0]), ...policy.InsuredItems.slice(1)]
    });
    if (res && res.Policy) {
      setPolicy(res.Policy);
    }

    return res;
  };

  const onContinue = async () => {
    if (currentPageIndex < props.pages.length - 1) {
      history.push(props.pages[currentPageIndex + 1].path.replace(':id', props.policy.ID));
    }
  };

  const initialFormData: unknown = {
    Price: props.policy?.InsuredItems?.[0].Price?.Amount?.toString() || '',
    Details: props.policy.InsuredItems[0].Details || {},
    HasNotReceived: props.policy.InsuredItems[0].HasNotReceived
  };

  return props.component(initialFormData as T, onSubmit, onContinue);
};

export const getPages = (
  routePrefix: string,
  policyId: string,
  policyType: PolicyType,
  onActivate: (policy: Policy) => void
): Page[] => {
  const pages: Page[] = [];
  pages.push({
    path: routePrefix + '/activate',
    render: () => (
      <Loadable request={getPolicyToActivate(policyId)}>
        {(data) => (
          <PageWrapper
            policyId={policyId}
            policy={data.Policy}
            page={(props) => (
              <>
                <PageSection noBorder style={{ paddingBottom: '0px' }}>
                  <h1>Final steps</h1>
                  <p>
                    Thanks for choosing Oyster! You're almost done &mdash; we just need to collect a
                    few more pieces of information.
                  </p>
                </PageSection>
                <PageSection>
                  <Button onClick={props.onNext} icon={<IoArrowForward />} primary>
                    Continue
                  </Button>
                </PageSection>
              </>
            )}
            pages={pages}
          />
        )}
      </Loadable>
    ),
    hasError: () => false,
    icon: <IoClipboardOutline />
  });

  switch (policyType) {
    case PolicyType.minicoJewelry:
      pages.push(
        {
          path: routePrefix + '/activate/location',
          render: () => (
            <Loadable request={getPolicyToActivate(policyId)}>
              {(data) => (
                <PageWrapper
                  policyId={policyId}
                  policy={data.Policy}
                  page={MinicoLocationPage}
                  pages={pages}
                />
              )}
            </Loadable>
          ),
          hasError: (validationError: ValidationError) =>
            !!['Storage', 'Construction'].find((prefix) =>
              validationError.Field.startsWith(prefix)
            ) || !!validationError.SubField?.startsWith('Address'),
          icon: <IoHomeOutline />
        },
        {
          path: routePrefix + '/activate/criteria',
          render: () => (
            <Loadable request={getPolicyToActivate(policyId)}>
              {(data) => (
                <PageWrapper
                  policyId={policyId}
                  policy={data.Policy}
                  page={MinicoJewelryCriteriaPage}
                  pages={pages}
                />
              )}
            </Loadable>
          ),
          hasError: (validationError: ValidationError) =>
            validationError.Field.startsWith('PriorLosses') ||
            [
              'ZipCode',
              'UnderwritingFlags',
              'UnderwritingJustification',
              'AdditionalInsuredFirstName',
              'AdditionalInsuredLastName',
              'CurrentInsurerName'
            ].includes(validationError.Field) ||
            !!['FirstName', 'LastName', 'Phone', 'Occupation', 'Email'].find(
              (subfield) => validationError.SubField === subfield
            ),
          icon: <IoPersonOutline />
        }
      );
      break;
    case PolicyType.chubbJewelry:
      pages.push(
        {
          path: routePrefix + '/activate/criteria',
          render: () => (
            <Loadable request={getPolicyToActivate(policyId)}>
              {(data) => (
                <PageWrapper
                  policyId={policyId}
                  policy={data.Policy}
                  page={ChubbJewelryCriteriaPage}
                  pages={pages}
                />
              )}
            </Loadable>
          ),
          hasError: (validationError: ValidationError) =>
            validationError.Field.startsWith('PriorLosses') ||
            [
              'ZipCode',
              'OccupationDetails',
              'AdditionalInsuredFirstName',
              'AdditionalInsuredLastName',
              'AdditionalInsuredOccupation',
              'AdditionalInsuredOccupationDetails',
              'InsuredTravel',
              'AverageJewelryValueOnTravel',
              'JewelrySafeguardOnTravel',
              'JewelrySafeguardAtHomeInsuredOnTravel'
            ].includes(validationError.Field) ||
            !![
              'FirstName',
              'LastName',
              'Phone',
              'Occupation',
              'Email',
              'DateOfBirth',
              'DateOfLoss',
              'LossType',
              'LossPaidDate',
              'LossPaidAmount',
              'CauseOfLossCode',
              'CauseOfLossDescription',
              'LossStatus'
            ].find((subfield) => validationError.SubField === subfield),
          icon: <IoPersonOutline />
        },
        {
          path: routePrefix + '/activate/location',
          render: () => (
            <Loadable request={getPolicyToActivate(policyId)}>
              {(data) => (
                <PageWrapper
                  policyId={policyId}
                  policy={data.Policy}
                  page={ChubbLocationPage}
                  pages={pages}
                />
              )}
            </Loadable>
          ),
          hasError: (validationError: ValidationError) =>
            !!['Storage', 'ConstructionType', 'Residence', 'InHouseEmployees'].find((prefix) =>
              validationError.Field.startsWith(prefix)
            ) || !!validationError?.SubField?.startsWith('Address'),
          icon: <IoHomeOutline />
        }
      );
      break;
    case PolicyType.markelMotorcycle:
    case PolicyType.markelOffroad:
      pages.push({
        path: routePrefix + '/activate/criteria',
        render: () => (
          <Loadable request={getPolicyToActivate(policyId)}>
            {(data) => (
              <PageWrapper
                policyId={policyId}
                policy={data.Policy}
                page={ATVCriteriaPage}
                pages={pages}
              />
            )}
          </Loadable>
        ),
        hasError: () => true,
        icon: <IoPersonOutline />
      });
      break;

    case PolicyType.bike:
    case PolicyType.markelBike:
      pages.push({
        path: routePrefix + '/activate/product',
        render: () => (
          <Loadable request={getPolicyToActivate(policyId)}>
            {(data) => (
              <CollectPagePage
                policy={data.Policy}
                pages={pages}
                component={(initialFormData: BikeFormData, onSubmit, onContinue) => (
                  <ProductInfoPage
                    title="Confirm your bike"
                    description="Double check that the information we have about your bike is correct."
                    initialFormData={initialFormData}
                    hasError={() => false}
                    onSubmit={onSubmit}
                    onContinue={onContinue}
                    component={CollectBikeInfo}
                  />
                )}
              />
            )}
          </Loadable>
        ),
        hasError: () => false,
        icon: <IoBicycleOutline />
      });

      pages.push({
        path: routePrefix + '/activate/criteria',
        render: () => (
          <Loadable request={getPolicyToActivate(policyId)}>
            {(data) => (
              <PageWrapper
                policyId={policyId}
                policy={data.Policy}
                page={BikeCriteriaPage}
                pages={pages}
              />
            )}
          </Loadable>
        ),
        hasError: (validationError: ValidationError) =>
          validationError.Field.startsWith('PriorLosses') ||
          [
            'StorageAddressLine1',
            'StorageCity',
            'StorageState',
            'StorageZipCode',
            'YearsOfExperience',
            'UsageTypes'
          ].includes(validationError.Field) ||
          !![
            'FirstName',
            'LastName',
            'Phone',
            'Email',
            'DateOfBirth',
            'Gender',
            'AddressLine1',
            'AddressLine2',
            'AddressCity',
            'AddressState',
            'AddressZipCode'
          ].find((subfield) => validationError.SubField === subfield),
        icon: <IoPersonOutline />
      });

      break;

    case PolicyType.worthAveElectronics:
      pages.push({
        path: routePrefix + '/activate/product',
        render: () => (
          <Loadable request={getPolicyToActivate(policyId)}>
            {(data) => (
              <CollectPagePage
                policy={data.Policy}
                pages={pages}
                component={(initialFormData: ElectronicsFormData, onSubmit, onContinue) => (
                  <ProductInfoPage
                    title="Tell us about your device"
                    description="We need a bit of information about your device before we can show you coverage options."
                    initialFormData={initialFormData}
                    hasError={() => false}
                    onSubmit={onSubmit}
                    onContinue={onContinue}
                    component={(props) => <CollectElectronicsInfo {...props} skipReceiptUpload />}
                  />
                )}
              />
            )}
          </Loadable>
        ),
        hasError: () => false,
        icon: <IoPhonePortraitOutline />
      });

      pages.push({
        path: routePrefix + '/activate/criteria',
        render: () => (
          <Loadable request={getPolicyToActivate(policyId)}>
            {(data) => (
              <PageWrapper
                policyId={policyId}
                policy={data.Policy}
                page={ElectronicsCriteriaPage}
                pages={pages}
              />
            )}
          </Loadable>
        ),
        hasError: () => true,
        icon: <IoPersonOutline />
      });

      break;
  }

  pages.push({
    path: routePrefix + '/activate/coverage',
    render: () => (
      <Loadable request={getPolicyToActivate(policyId)}>
        {(data) => (
          <PageWrapper
            policyId={policyId}
            policy={data.Policy}
            page={getCoveragePage(
              'Confirm your coverage',
              'Oyster coverage is flexible and comprehensive. You can also add additional coverages to your policy.',
              (updatedPolicy?: Policy) => validateUserActivationPolicy(policyId, updatedPolicy),
              policyType == PolicyType.chubbJewelry,
              undefined,
              true
            )}
            pages={pages}
          />
        )}
      </Loadable>
    ),
    hasError: (validationError: ValidationError) =>
      !!['Source', 'TrackingNumber', 'StartDate'].find(
        (subfield) => validationError.SubField == subfield
      ),
    icon: <IoDocumentTextOutline />
  });

  pages.push({
    path: routePrefix + '/activate/payment',
    render: () => (
      <ExistingUserWrapper>
        {(account) => (
          <Loadable request={getPolicyToActivate(policyId)}>
            {(data) => (
              <PageWrapper
                policyId={policyId}
                policy={data.Policy}
                page={getPaymentPage(
                  () => userGetPaymentSession(policyId),
                  window.location.origin + `/policies/${policyId}/overview/activate/confirm`,
                  account
                )}
                pages={pages}
              />
            )}
          </Loadable>
        )}
      </ExistingUserWrapper>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoBagCheckOutline />
  });

  pages.push({
    path: routePrefix + '/activate/confirm',
    render: (props) => (
      <Loadable request={getPolicyToActivate(props.match.params.id || '')}>
        {(data) => (
          <ActivationPage
            token={data.Policy.ID}
            policy={data.Policy}
            insured={getUser() as Insured}
            onActivate={onActivate}
          />
        )}
      </Loadable>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoCheckmarkDoneOutline />
  });
  return pages;
};

// manual activation pages
export const getManualActivationPages = (
  routePrefix: string,
  policyId: string,
  onActivate: (policy: Policy) => void
): Page[] => {
  const pages: Page[] = [];
  pages.push({
    path: routePrefix + '/activate/manual',
    render: () => (
      <Loadable request={getPolicyToActivate(policyId)}>
        {(data) => (
          <PageWrapper
            policyId={policyId}
            policy={data.Policy}
            page={(props) => (
              <>
                <PageSection noBorder style={{ paddingBottom: '0px' }}>
                  <h1>Activate your policy</h1>
                  <p>
                    Thank you for choosing Oyster! Click the "Activate" button to perform final
                    checks on your policy and submit it to our team for review.
                  </p>
                  <p>If final checks pass, your coverage should be active within 1 business day.</p>
                </PageSection>
                <PageSection>
                  <Button onClick={props.onNext} icon={<IoArrowForward />} primary>
                    Activate Policy
                  </Button>
                </PageSection>
              </>
            )}
            pages={pages}
          />
        )}
      </Loadable>
    ),
    hasError: () => false,
    icon: <></>
  });

  pages.push({
    path: routePrefix + '/activate/done',
    render: (props) => (
      <Loadable request={getPolicyToActivate(props.match.params.id || '')}>
        {(data) => (
          <ActivationPage
            token={data.Policy.ID}
            policy={data.Policy}
            insured={getUser() as Insured}
            onActivate={onActivate}
            overrideManualActivationTrigger
          />
        )}
      </Loadable>
    ),
    hasError: () => false,
    icon: <></>
  });

  return pages;
};
