import styled from 'styled-components';

import { IoAttachOutline, IoClose } from 'react-icons/io5';

const AttachmentContainer = styled.div<{ uploading?: boolean }>`
  opacity: ${(props) => (props.uploading ? 0.5 : 1.0)};
  pointer-events: ${(props) => (props.uploading ? 'none' : 'auto')};
  transition: all 0.15s ease-in-out;

  font-size: 0.8em;
  padding: 5px 10px;
  background: #eaeaea;
  border-radius: 30px;
  color: #333333;

  * {
    vertical-align: middle;
  }

  .close-icon {
    color: #666666;
    cursor: pointer;

    &:hover {
      color: #000000;
    }
  }
`;

const formatSize = (size: number) => {
  if (size < 1024) {
    return `${size}B`;
  }
  if (size < 1024 * 1024) {
    return `${Math.round(size / 1024)}KB`;
  }
  if (size < 1024 * 1024 * 1024) {
    return `${Math.round(size / (1024 * 1024))}MB`;
  }
  return 'big';
};

export const Attachment = (props: {
  name: string;
  url?: string;
  size?: number;
  uploading?: boolean;
  onRemove?: () => void;
}): JSX.Element => (
  <AttachmentContainer
    uploading={props.uploading}
    style={{ cursor: props.url ? 'pointer' : undefined }}
    onClick={(e) => e.stopPropagation()}
  >
    <IoAttachOutline style={{ paddingRight: '4px' }} aria-hidden />
    {props.name}
    {props.size && (
      <>
        {'  '}({formatSize(props.size)})
      </>
    )}
    {props.onRemove && (
      <IoClose
        aria-label="Remove attachment"
        role="button"
        tabIndex={0}
        className="close-icon"
        style={{ paddingLeft: '8px' }}
        onClick={(e) => {
          e.stopPropagation();
          if (props.onRemove) {
            props.onRemove();
          }
        }}
      />
    )}
  </AttachmentContainer>
);
