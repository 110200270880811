import { Insured, User } from '@oysterjs/types';
import { Post } from './base';

export const userSignUp = (data: { Insured: Insured; AccessCode: string }) =>
  Post<{ Token: string; User: User }>('/user/signup', data, { disableUnauthorizedRedirect: true });

export const userSignInInit = (email: string, redirect?: string) =>
  Post(
    '/user/signin/init',
    { Email: email, Redirect: redirect },
    { disableUnauthorizedRedirect: true }
  );

export const userSignInComplete = (email: string, code: string) =>
  Post<{ Token: string; User: User }>(
    '/user/signin/complete',
    { Email: email, LoginCode: code },
    { disableUnauthorizedRedirect: true }
  );

export const userSignOut = () => Post('/user/signout', { disableUnauthorizedRedirect: true });
