import * as React from 'react';
import { IoArrowBack, IoClose } from 'react-icons/io5';
import styled from 'styled-components';

const Overlay = styled.div<{ disableMobileView?: boolean }>`
  background: radial-gradient(rgba(45, 45, 45, 0.25), rgba(0, 0, 0, 0.6));
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  ${(props) =>
    !props.disableMobileView &&
    `@media (max-width: 600px) {
      .outer {
        margin: 0 !important
      }
      .inner {
        margin: 0 !important;
        min-height: 100% !important;
      }
    }`}
`;

const Popup = styled.div<{ disableMobileView?: boolean }>`
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: white;
  box-shadow: 0px 0px 24px rgba(80, 80, 80, 0.5);
  border-radius: 8px;
  width: 90%;
  min-width: 500px;
  max-width: 600px;
  box-sizing: border-box;

  ${(props) =>
    !props.disableMobileView &&
    `@media (max-width: 600px) {
      top: 0;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      border-radius: 0px;
    }`}
`;

const ContentBox = styled.div`
  box-sizing: border-box;
  padding: 40px 60px;
`;

const CloseIcon = styled.div`
  font-size: 1.4em;
  position: absolute;
  top: 0;
  right: 0;
  color: #999999;

  :hover {
    color: #333333;
    cursor: pointer;
  }
`;

const BackIcon = styled.div`
  font-size: 1.4em;
  position: absolute;
  top: 0;
  left: 0;
  color: #999999;

  :hover {
    color: #333333;
    cursor: pointer;
  }
`;

const Header = (props: {
  headerContents: JSX.Element;
  onClose: () => void;
  onBack?: () => void;
}) => (
  <div
    style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    {props.headerContents}
    <CloseIcon>
      <IoClose aria-label="Close" onClick={props.onClose} />
    </CloseIcon>
    {props.onBack && (
      <BackIcon>
        <IoArrowBack aria-label="Back" onClick={props.onBack} />
      </BackIcon>
    )}
  </div>
);

interface ModalProps {
  headerContents: JSX.Element;
  disableMobileView?: boolean;

  onClose: () => void;
  onBack?: () => void;
}

export const Modal: React.FunctionComponent<
  React.AllHTMLAttributes<HTMLDivElement> & ModalProps
> = (props) => (
  <Overlay onClick={props.onClose} disableMobileView={props.disableMobileView}>
    <div className="outer" style={{ margin: 'auto', width: '100%' }}>
      <div
        className="inner"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          margin: '48px auto',
          overflow: 'hidden',
          position: 'relative',
          transform: 'translateZ(0)',
          alignItems: 'stretch'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            minHeight: '400px'
          }}
        >
          <Popup onClick={(e) => e.stopPropagation()} {...props}>
            <ContentBox>
              <Header
                headerContents={props.headerContents}
                onClose={props.onClose}
                onBack={props.onBack}
              />
              {props.children}
            </ContentBox>
          </Popup>
        </div>
      </div>
    </div>
  </Overlay>
);

export const CustomModal: React.FunctionComponent<React.AllHTMLAttributes<HTMLDivElement>> = (
  props
) => (
  <Overlay>
    <div className="outer" style={{ margin: 'auto', width: '100%' }}>
      <div
        className="inner"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          margin: '48px auto',
          overflow: 'hidden',
          position: 'relative',
          transform: 'translateZ(0)',
          alignItems: 'stretch'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            minHeight: '400px'
          }}
        >
          <Popup onClick={(e) => e.stopPropagation()} {...props}>
            {props.children}
          </Popup>
        </div>
      </div>
    </div>
  </Overlay>
);
