import * as React from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0px 50px 0px;
  position: relative;

  .min-label {
    position: absolute;
    top: -24px;
    left: 0px;
    font-weight: 500;
  }

  .max-label {
    position: absolute;
    top: -24px;
    right: 0px;
    font-weight: 500;
  }

  .slider-label {
    position: absolute;
    bottom: -38px;
    left: 50%;
    padding: 5px 10px;
    background: #efefef;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
  }
`;

const RangeInput = styled.input`
  appearance: none;
  box-sizing: border-box;
  margin-right: 15px;
  width: 100%;
  height: 7px;
  background: rgba(200, 200, 200, 0.6);
  border-radius: 5px;
  /* background-image: linear-gradient(to right, #0EA5E9, #00a6c7); */
  background-image: linear-gradient(to right, #0ea5e9, #0ea5e9);
  background-repeat: no-repeat;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #f2f2f2;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
  }

  ::-webkit-slider-thumb:active {
    background: #ffffff;
  }

  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const Slider = ({
  min,
  max,
  initialValue,
  interval,
  formatter
}: {
  min: number;
  max: number;
  initialValue: number;
  interval?: number;
  formatter?: (val: number) => string;
}): JSX.Element => {
  const [value, setValue] = React.useState(
    (interval || 1) * Math.floor(initialValue / (interval || 1))
  );
  const labelRef = React.useRef<HTMLDivElement>(null);
  const pos = ((value - min) * 100) / (max - min);

  React.useEffect(() => {
    if (labelRef.current) {
      const offset = labelRef.current.offsetWidth / 2;
      labelRef.current.style.left = `calc(${pos}% - ${offset}px)`;
    }
  });

  const format = (val: number) => (formatter ? formatter(val) : val.toString());

  return (
    <SliderContainer>
      <RangeInput
        type="range"
        min={min}
        max={max}
        step={interval}
        value={value}
        onInput={(e) => setValue(parseInt(e.currentTarget.value))}
        style={{ backgroundSize: `${pos}% 100%` }}
      ></RangeInput>
      <div className="slider-label" ref={labelRef}>
        {format(value)}
      </div>
      <div className="min-label">{format(min)}</div>
      <div className="max-label">{format(max)}</div>
    </SliderContainer>
  );
};
