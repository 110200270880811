import * as React from 'react';
import { IoCheckmark, IoCopyOutline, IoPaperPlane } from 'react-icons/io5';

import { Button } from '@oysterjs/ui/Button';
import { TextInput } from '@oysterjs/ui/Form/text';
import { PageSection, TwoPaneContainer } from '@oysterjs/ui/Page';
import { getUser } from '@oysterjs/core/auth';
import { Loadable } from '@oysterjs/ui/Loadable';
import { getReferralList } from '@oysterjs/core/api/user';
import { VerticalLayout, VerticalLayoutItem } from '@oysterjs/ui/VerticalLayout';
import config from '@oysterjs/core/config';
import { ReferralStatus, User } from '@oysterjs/types';

const ActivityItem = (props: {
  referree: string;
  date: string;
  amount: string;
  last?: boolean;
}) => (
  <VerticalLayoutItem icon={<IoPaperPlane />} last={props.last}>
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '2px'
        }}
      >
        <div style={{ fontWeight: 500 }}>{props.referree}</div>
        <div style={{ paddingLeft: '5px', boxSizing: 'border-box' }}>{props.amount}</div>
      </div>
      <div style={{ fontSize: '0.9em', color: '#666666', padding: '2px 0px' }}>{props.date}</div>
    </>
  </VerticalLayoutItem>
);

const ReferralList = () => {
  return (
    <>
      <h2>Your Referrals</h2>
      <Loadable
        request={getReferralList().then((r) =>
          r.filter((r) => r.Status === ReferralStatus.fulfilled)
        )}
        inline
      >
        {(data) => (
          <>
            {data.length === 0 && <p style={{ color: '#999999' }}>No referrals yet.</p>}
            {data.length > 0 && (
              <VerticalLayout activeIconColor="#0EA5E9">
                {data.map((referral, i) => (
                  <ActivityItem
                    key={referral.ID}
                    referree={`${referral.Referee.FirstName} ${referral.Referee.LastName}`}
                    date={new Date(referral.UpdatedAt).toLocaleDateString()}
                    amount={new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'usd',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(referral.Reward)}
                    last={i === data.length - 1}
                  />
                ))}
              </VerticalLayout>
            )}
          </>
        )}
      </Loadable>
    </>
  );
};

const getInviteLinkHost = (): string => {
  switch (config().environment) {
    case 'production':
      return 'https://withoyster.com/invite';
    case 'staging':
      return 'https://withoyster.com/invite-staging';
    case 'dev':
    case 'local':
      return 'https://withoyster.com/invite-local';
  }
};

export const InvitePage = (): JSX.Element => {
  const handle = getUser<User>()?.PublicHandle;
  const link = `${getInviteLinkHost()}/${handle}`;

  const [copyClicked, setCopyClicked] = React.useState(false);

  React.useEffect(() => {
    if (!copyClicked) {
      return;
    }

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link).catch(() => 0);
    }

    const timeout = setTimeout(() => {
      setCopyClicked(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [copyClicked]);

  return (
    <PageSection>
      <TwoPaneContainer leftPaneWidth={65} rightPane={<ReferralList />}>
        <h1>Invite a Friend</h1>
        <p>
          Refer a friend to Oyster and you'll both receive a $20 Amazon gift card after they sign up
          and purchase their first policy.
        </p>
        <img src="/images/celebrate.svg" style={{ width: '100%' }} aria-hidden />

        <h2 style={{ marginBottom: '0' }}>Share your link</h2>
        <p style={{ marginTop: '5px', marginBottom: '20px', color: '#999999', fontSize: '0.8em' }}>
          You can earn up to $100 in gift card credits per calendar year.
        </p>
        <div style={{ display: 'block' }}>
          <div style={{ display: 'flex', gap: '10px', paddingBottom: '20px' }}>
            <div style={{ flex: '1 1 0' }}>
              <TextInput disabled value={link} style={{ maxWidth: '100%' }} />
            </div>
            <div style={{ minWidth: '120px' }}>
              <Button
                icon={copyClicked ? <IoCheckmark /> : <IoCopyOutline />}
                primary
                disabled={copyClicked}
                onClick={() => setCopyClicked(true)}
                onPointerUp={() => setCopyClicked(true)}
              >
                Copy Link
              </Button>
            </div>
          </div>
        </div>
      </TwoPaneContainer>
    </PageSection>
  );
};
