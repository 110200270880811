import { Policy, Price, Product } from '@oysterjs/types';
import { Delete, Post } from './base';

export const addEndorsement = (policyId: string, product: Product) =>
  Post<{ Policy: Policy }>(`/user/policies/${policyId}/endorsement`, { InsuredItem: product });

export const getEndorsementPremium = (policyId: string, product: Product) =>
  Post<{ CurrentPrice: Price; PendingPrice: Price; EndorsementPrice: Price }>(
    `/user/policies/${policyId}/endorsement/premium`,
    {
      InsuredItem: product
    }
  );

export const cancelEndorsement = (policyId: string, productId: string) =>
  Delete<{ Policy: Policy }>(`/user/policies/${policyId}/endorsement/${productId}`);
