import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import * as Sentry from '@sentry/react';
import { Route as BrowserRoute, useRouteMatch, useHistory } from 'react-router-dom';

const Route = Sentry.withSentryRouting(BrowserRoute);

import { PageSection } from '@oysterjs/ui/Page';

import {
  Policy,
  ElectronicsType,
  ProductType,
  PolicyType,
  Product,
  BikeProduct,
  PolicyState,
  AccountSummary
} from '@oysterjs/types';
import { getPolicyDisplayImage, getPolicyDisplayName, getPolicyHref } from '@oysterjs/core/policy';
import { Badge } from '@oysterjs/ui/Badge';
import { IoArrowForward } from 'react-icons/io5';
import { getPolicyStatus } from './policy';
import { ButtonLink, Button } from '@oysterjs/ui/Button';
import BikeInsuranceApp from '@oysterjs/getoyster/pages/bike';
import JewelryInsuranceApp from '@oysterjs/getoyster/pages/jewelry';
import PhoneInsuranceApp from '@oysterjs/getoyster/pages/electronics';
import ElectronicsInsuranceApp from '@oysterjs/getoyster/pages/electronics';
import MotorInsuranceApp from '@oysterjs/getoyster/pages/motor';
import CompletePage from '@oysterjs/getoyster/pages/complete';
import {
  Box,
  BoxBadgeHeader,
  BoxItemImage,
  AlternateBoxTitle,
  LeftAlignBoxContents,
  BoxContainer
} from '@oysterjs/ui/Box';
import { ComingSoon } from '@oysterjs/ui/Card';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { Banner } from '@oysterjs/ui/Banner';

const policyList = [
  {
    types: ['minico_jewelry', 'chubb_jewelry', 'jewelry'],
    href: '/app/jewelry',
    title: 'Jewelry Insurance',
    description: 'For your rings, watches, bracelets, earrings, and more.',
    image: '/images/jewelry_insurance.svg'
  },
  {
    types: ['markel_bike', 'bike'],
    href: '/app/bike',
    title: 'Bike Insurance',
    description: 'For your bikes, eBikes, and scooters.',
    image: '/images/bike_insurance.svg'
  },
  {
    types: ['markel_offroad'],
    href: '/app/offroad_vehicle',
    title: 'ATV Insurance',
    description: 'For your ATVs, UTVs, and snowmobiles.',
    image: '/images/atv_insurance.svg',
    hideD2C: true
  },
  {
    types: ['markel_motorcycle'],
    href: '/app/motorcycle',
    title: 'Motorcycle Insurance',
    description: 'For your motorcycles.',
    image: '/images/motorcycle_insurance.svg',
    hideD2C: true
  },
  {
    types: ['worth_ave_electronics', 'phone'],
    href: '/app/phone',
    title: 'Phone Insurance',
    description: 'For your iPhone or other mobile phone.',
    image: '/images/phone_insurance.svg'
  },
  {
    types: ['worth_ave_electronics', 'electronics'],
    title: 'Electronics Insurance',
    href: '/app/electronics',
    description: 'For your laptops, cameras, tablets, and more.',
    image: '/images/electronics_insurance.svg'
  },
  {
    types: ['collectibles'],
    title: 'Collectibles Insurance',
    description: 'For your fine art, rare collectibles, and more.',
    image: '/images/collectibles_insurance.svg'
  }
  // {
  //   type: 'accessories',
  //   title: 'Accessory Insurance',
  //   description: 'For your clothing, handbags, and wearables.',
  //   image: '/images/accessories_insurance.svg'
  // },
  // {
  //   type: 'sports',
  //   title: 'Sports Insurance',
  //   description: 'For your golf clubs, fishing poles, and equipment.',
  //   image: '/images/sports_insurance.svg'
  // },
  // {
  //   type: 'instruments',
  //   title: 'Instrument Insurance',
  //   description: 'For your pianos, guitars, saxophones, and more.',
  //   image: '/images/instruments_insurance.svg'
  // }
];

const getPolicyBadge = (policy: Policy, account: AccountSummary): JSX.Element => {
  const status = getPolicyStatus(policy, account);
  return <Badge icon={status.badge} color={status.backgroundColor} label={status.stateText} />;
};

const getProductTypeFromPolicy = (policy: Policy): string => {
  return policy.InsuredItems[0]?.Details?.Type;
};

export const PoliciesPage = ({
  policies,
  account
}: {
  policies: Policy[];
  account: AccountSummary;
}): JSX.Element => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const noAppPolicies = policyList
    .filter(
      (p) =>
        !policies?.find((pp) => {
          // filter electronics policy type
          if (p.types.includes(PolicyType.worthAveElectronics)) {
            // hide electronics policy if purchased
            if (
              p.types.includes(ProductType.electronics) &&
              [
                ElectronicsType.laptop,
                ElectronicsType.iPad,
                ElectronicsType.smallElectronics,
                ElectronicsType.gamingSystem,
                ElectronicsType.eReaderKindle,
                ElectronicsType.tablet
              ].includes(pp.InsuredItems[0]?.Details?.Type as ElectronicsType)
            ) {
              return true;
            }
            // hide phone policy if purchased
            else if (
              p.types.includes(ProductType.phone) &&
              [ElectronicsType.iPhone, ElectronicsType.smartPhone].includes(
                pp.InsuredItems[0]?.Details?.Type as ElectronicsType
              )
            ) {
              return true;
            }
            return false;
          }

          return p.types.includes(pp.Type);
        })
    )
    .filter((p) => !p.hideD2C);

  const applyPolicies = noAppPolicies.filter((p) => !!p.href);
  const comingSoonPolicies = noAppPolicies.filter((p) => !p.href);

  const [showPolicyAppModal, setShowPolicyAppModal] = React.useState(false);

  React.useEffect(() => {
    setShowPolicyAppModal(!url.match('/policies'));
  }, [url]);

  return (
    <>
      <SlideOut
        showing={showPolicyAppModal}
        onClose={() => {
          setShowPolicyAppModal(false);
          history.push('/policies');
        }}
        onBack={() => {
          setShowPolicyAppModal(false);
          history.push('/policies');
        }}
        title="Add a new Policy"
      >
        <ErrorBoundary>
          <Route path="/app/bike">
            <BikeInsuranceApp />
          </Route>
          <Route path="/app/jewelry">
            <JewelryInsuranceApp />
          </Route>
          <Route path="/app/phone">
            <PhoneInsuranceApp />
          </Route>
          <Route path="/app/electronics">
            <ElectronicsInsuranceApp />
          </Route>
          <Route path="/app/offroad_vehicle">
            <MotorInsuranceApp />
          </Route>
          <Route path="/app/motorcycle">
            <MotorInsuranceApp />
          </Route>
          <Route
            path="/policy/:id/complete"
            render={(props) => <CompletePage policyId={props.match.params.id} />}
          />
        </ErrorBoundary>
      </SlideOut>
      <PageSection noBorder>
        <ReactTooltip effect="solid" className="tooltip" multiline />
        <BikeSerialNumberBanner policies={policies} />
        <h1>Your Policies</h1>
        {policies.length === 0 && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src="/images/no_policies.svg"
              style={{ width: '35%', minWidth: '240px' }}
              alt="No policies"
            />
            <p style={{ color: '#999999' }}>You don't have any policies yet. Get one below!</p>
          </div>
        )}
        {policies.length > 0 && (
          <BoxContainer>
            {policies.map((policy) => (
              <Box className="policy-box-item">
                <BoxBadgeHeader>{getPolicyBadge(policy, account)}</BoxBadgeHeader>
                <BoxItemImage
                  src={getPolicyDisplayImage(policy.Type, getProductTypeFromPolicy(policy))}
                />
                <AlternateBoxTitle>{getPolicyDisplayName(policy) + ' Insurance'}</AlternateBoxTitle>
                <div style={{ flex: '1' }}>
                  <LeftAlignBoxContents>
                    <div style={{ color: '#000000', fontSize: '0.8em' }}>
                      {getPolicyStatus(policy, account).description}
                    </div>
                    <ButtonLink
                      primary
                      href={getPolicyHref(policy, account)}
                      icon={<IoArrowForward />}
                    >
                      {getPolicyStatus(policy, account).buttonText}
                    </ButtonLink>
                  </LeftAlignBoxContents>
                </div>
              </Box>
            ))}
          </BoxContainer>
        )}
      </PageSection>
      {applyPolicies.length > 0 && (
        <PageSection>
          <h1>Get New Policies</h1>
          <BoxContainer>
            {applyPolicies.map(
              (p) =>
                p.href && (
                  <Box className="policy-box-item">
                    <BoxItemImage src={p.image} />
                    <AlternateBoxTitle>{p.title}</AlternateBoxTitle>
                    <div style={{ flex: '1' }}>
                      <LeftAlignBoxContents>
                        <div style={{ color: '#000000', fontSize: '0.8em' }}>{p.description}</div>
                        <Button
                          primary
                          onClick={(e) => {
                            setShowPolicyAppModal(true);
                            history.push(p.href);
                            e.stopPropagation();
                          }}
                          icon={<IoArrowForward />}
                        >
                          Apply
                        </Button>
                      </LeftAlignBoxContents>
                    </div>
                  </Box>
                )
            )}
          </BoxContainer>
        </PageSection>
      )}
      {comingSoonPolicies.length > 0 && (
        <PageSection>
          <h1>Coming Soon</h1>
          <BoxContainer>
            {comingSoonPolicies.map(
              (p) =>
                !p.href && (
                  <Box className="policy-box-item">
                    <BoxItemImage src={p.image} />
                    <AlternateBoxTitle>{p.title}</AlternateBoxTitle>
                    <div style={{ flex: '1' }}>
                      <LeftAlignBoxContents>
                        <div style={{ color: '#000000', fontSize: '0.8em' }}>{p.description}</div>
                        <ComingSoon product={p.title} />
                      </LeftAlignBoxContents>
                    </div>
                  </Box>
                )
            )}
          </BoxContainer>
        </PageSection>
      )}
    </>
  );
};

export const BikeSerialNumberBanner = (props: { policies?: Policy[] }): JSX.Element => {
  const isPolicySubmittedOrActive = (policy: Policy): boolean => {
    return (
      policy.State === PolicyState.applicationSubmitted ||
      policy.State === PolicyState.policyBinding ||
      policy.State === PolicyState.policyInforce
    );
  };

  const getSerialNumberLink = (policy: Policy, product: Product): string => {
    return `/policies/${policy.ID}/items/${product.ID}/serialnumber`;
  };

  const [pendingLinks, setPendingLinks] = React.useState<string[]>([]);
  React.useEffect(() => {
    let pending: string[] = [];
    props.policies?.forEach((policy) => {
      if (
        isPolicySubmittedOrActive(policy) &&
        (policy.Type == PolicyType.bike || policy.Type == PolicyType.markelBike)
      ) {
        pending = [
          ...pending,
          ...policy.InsuredItems.filter(
            (item) =>
              item.Type === ProductType.bike && !(item.Details as BikeProduct).FrameSerialNumber
          ).map((item) => getSerialNumberLink(policy, item))
        ];
      }
    });

    setPendingLinks(pending);
  }, [props.policies]);

  return (
    <>
      {pendingLinks.length > 0 && (
        <Banner
          title={`Add your bike's serial number`}
          description={'Provide your bike serial number to help us process your insurance policy.'}
          bannerAction={
            // We link to the first pending product
            <ButtonLink href={pendingLinks[0]} primary icon={<IoArrowForward />}>
              Provide Serial Number
            </ButtonLink>
          }
        ></Banner>
      )}
    </>
  );
};
