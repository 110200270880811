import { Policy, Price, ValidationError } from '@oysterjs/types';
import { Get, getEncodedQueryString, Post, Put } from './base';

export interface ActivateResponse {
  Policy: Policy;
  NextValidationError?: ValidationError;
}

export const getPolicyToActivate = (policyId: string): Promise<ActivateResponse> =>
  Get<{ Policy: Policy }>(`/user/policies/${policyId}`);

export const updatePolicyActivation = (policyId: string, policy: Policy) =>
  Put<ActivateResponse>(`/user/policies/${policyId}`, { Policy: policy });

export const confirmActivationPolicy = (
  policyId: string,
  overrideManualActivationTrigger?: boolean
) =>
  Post<{ Policy: Policy }>(
    `/user/policies/${policyId}/activate?${getEncodedQueryString({
      overrideManualActivationTrigger: overrideManualActivationTrigger
    })}`
  );

export const getUserActivationPolicyPremium = (policyID: string) =>
  Get<{ Premium: Price }>(`/user/policies/${policyID}/premium`);

export const validateUserActivationPolicy = (
  policyId: string,
  updatedPolicy?: Policy
): Promise<Policy> =>
  Post<{ Policy: Policy }>(`/user/policies/${policyId}/partnerValidation`, {
    Policy: updatedPolicy
  }).then((d) => d.Policy);
